/**
 * Used with Fuse library for speedy search results.
 */

// Supermove
import {useState, SetStateType} from '@supermove/hooks';
import {Fuse} from '@supermove/utils';

interface UseSearchArgs<T> {
  initialQuery: string;
  options: Fuse.IFuseOptions<unknown>;
  items: T[];
  limit: number;
}

export type SearchResults<T> = {
  results: T[];
  query: string;
  setQuery: SetStateType<string>;
};

const useSearch = <T>({
  initialQuery,
  options,
  items,
  limit,
}: UseSearchArgs<T>): SearchResults<T> => {
  const [query, setQuery] = useState(initialQuery);
  const fuse = new Fuse(items, options);
  const fuseResults = fuse.search(query, {limit});
  const results = query ? fuseResults.map((result) => result.item) : items;

  return {
    results,
    query,
    setQuery,
  };
};

export default useSearch;
