/**
 * Component - v2.1.0
 */

// Libraries
import Tippy from '@tippyjs/react/headless';
import React from 'react';
import {sticky, followCursor, Placement} from 'tippy.js';

interface PopoverProps {
  handleOpen: () => void;
  handleClose: () => void;
  reference: React.RefObject<Element> | null | undefined;
  isOpen: boolean;
  children: React.ReactNode;
  isClosableOutside?: boolean;
  placement?: PopoverPlacementType;
  initialPlacementOnClick?: boolean;
  offset?: [number, number];
  arrow?: boolean;
  onComponentDidHide?: () => void;
  zIndex?: number;
}

/*
 * Add popover content by passing in a component into the *content* argument.
 * Wrap the static object with the Popover object like:
 *
 *  const {ref, isOpen, handleOpen, handleClose, handleToggle} = usePopover();
 *  <span tabIndex={'0'} ref={ref} />
 *    <SomeTouchable onPress={onPress} />}
 *  </span>
 *  <Popover
 *     placement={Popover.Positions.BottomStart}
 *     isOpen={isOpen}
 *     handleOpen={handleOpen}
 *     handleClose={handleClose}
 *     reference={ref}
 *   >
 *    <ContentYouWantToDisplay />
 *  </Popover>
 * To update this component, refer to https://atomiks.github.io/tippyjs/v6/all-props/ for more customizations.
 */
const Popover = ({
  handleOpen,
  handleClose,
  reference,
  isOpen,
  children,
  isClosableOutside = true,
  placement = POSITION_AUTO,
  initialPlacementOnClick = false,
  offset = [0, 0],
  arrow = false,
  onComponentDidHide = () => {},
  zIndex = 0,
}: PopoverProps) => {
  return (
    <Tippy
      placement={placement as Placement}
      theme={'light'}
      offset={offset} // Add x,y coordinate offset from reference element
      arrow={arrow}
      render={() => children}
      visible={isOpen}
      onShow={handleOpen}
      onClickOutside={isClosableOutside ? handleClose : () => {}} // Use handleClose on visible if this is desired
      appendTo={document.body} // Handles interactive mode, to put above everything else
      reference={reference}
      plugins={[sticky, followCursor]}
      interactive
      onHidden={onComponentDidHide}
      sticky
      zIndex={zIndex}
      followCursor={initialPlacementOnClick ? 'initial' : false}
    />
  );
};

export type PopoverPlacementType =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';

const POSITION_AUTO = 'auto';
const POSITION_AUTO_START = 'auto-start';
const POSITION_AUTO_END = 'auto-end';
const POSITION_TOP = 'top';
const POSITION_TOP_START = 'top-start';
const POSITION_TOP_END = 'top-end';
const POSITION_RIGHT = 'right';
const POSITION_RIGHT_START = 'right-start';
const POSITION_RIGHT_END = 'right-end';
const POSITION_BOTTOM = 'bottom';
const POSITION_BOTTOM_START = 'bottom-start';
const POSITION_BOTTOM_END = 'bottom-end';
const POSITION_LEFT = 'left';
const POSITION_LEFT_START = 'left-start';
const POSITION_LEFT_END = 'left-end';

Popover.Positions = {
  Auto: POSITION_AUTO,
  AutoStart: POSITION_AUTO_START,
  AutoEnd: POSITION_AUTO_END,
  Top: POSITION_TOP,
  TopStart: POSITION_TOP_START,
  TopEnd: POSITION_TOP_END,
  Bottom: POSITION_BOTTOM,
  BottomStart: POSITION_BOTTOM_START,
  BottomEnd: POSITION_BOTTOM_END,
  Right: POSITION_RIGHT,
  RightStart: POSITION_RIGHT_START,
  RightEnd: POSITION_RIGHT_END,
  Left: POSITION_LEFT,
  LeftStart: POSITION_LEFT_START,
  LeftEnd: POSITION_LEFT_END,
} as const;

// We use *innerRef* because we need to pass the ref to the span to get
// right positioning and we don't want to alias to React's *ref* prop.
const Content = ({
  innerRef,
  children,
}: {
  innerRef: React.RefObject<HTMLSpanElement> | undefined;
  children: React.ReactNode;
}) => {
  return (
    /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
    // @ts-expect-error tabIndex expects a number
    <span tabIndex={'0'} ref={innerRef}>
      {children}
    </span>
    /* eslint-enable jsx-a11y/no-noninteractive-tabindex */
  );
};

Popover.Content = Content;

export default Popover;
